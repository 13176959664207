.create-overlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: fixed;

    >.create-form-box{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 75%;
        height: 75%;
        background-color: #232323;
        border-radius: 1rem;
        padding: 1rem;
        gap: 1.5rem;
        font-family: 'Raleway', sans-serif;

        >.back-container{
            width: 100%;

            >.back-button{
                font-size: 3rem;
                color: white;

                &:hover{
                    cursor: pointer;
                    color: #F25D5A;
                }
            }
        }

        >.create-form-header{
            color: white;
            font-size: 3rem;
            // margin-bottom: 1rem;
            text-align: center;

            @media only screen and (max-width: 600px){
                font-size: 2rem;
            }
        }


        >.create-form-desc{
            color: #AFAFAF;
            font-size: 1rem;
            text-align: center;
            margin-bottom: 0.5rem;


            @media only screen and (max-width: 600px){
                font-size: 0.75rem;
            }
        }

        >.create-form{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            height: 50%;

            >.room-name-input{
                width: 75%;
                height: 2rem;
                border-radius: 1rem;
                background: #232323;
                padding: 0.5rem;
                border: 2px solid #AFAFAF;
                color: #AFAFAF;
                font-family: 'Raleway', sans-serif;
            

                &:focus{
                  color: white;
                  outline: none;
                  border-color: #A9EBFA;
                }
            }

            >.room-duration-input{
                width: 75%;
                height: 2rem;
                border-radius: 1rem;
                background: #232323;
                padding: 0.5rem;
                border: 2px solid #AFAFAF;
                color: #AFAFAF;
                font-family: 'Raleway', sans-serif;
                box-sizing: content-box;
                -webkit-appearance: none;  // Remove default styling in WebKit browsers
                -moz-appearance: none;     // Remove default styling in Firefox
                appearance: none;          // Remove default styling in other browsers
                background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23AFAFAF" d="M0 0L2 2 4 0z"/></svg>');
                background-repeat: no-repeat;
                background-position: 97.5% 1.25rem; // Adjust this value to move the arrow left
                background-size: 0.65rem;
                
                &:hover{
                    cursor: pointer;
                }

                &:focus{
                  color: white;
                  outline: none;
                  border-color: #A9EBFA;
                }
            }

            >.create-button{
                height: 1.5rem;
                border-radius: 1rem;
                background: #232323;
                padding: 0.5rem;
                border: 2px solid #AFAFAF;
                color: #AFAFAF;
                font-family: 'Raleway', sans-serif;
                box-sizing: content-box;

                &:hover{
                    cursor: pointer;
                    border: 2px solid #A9EBFA;
                    color: #A9EBFA;
                }
            }
        }
    }
}