.join-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 1rem;

    >.join-label{
        color: white;
        font-size: 2rem;
        font-family: 'Raleway', sans-serif;
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
    }
}