@import url('https://fonts.googleapis.com/css2?family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.login-content{
    width: 100%;
    height: 92.5vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.login-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        // border: 1px solid #A9EBFA;
        background: rgb(48, 47, 47);
        border-radius: 2rem;
        height: 30%;
        width: 50%;
        padding: 0.5rem;
        font-family: 'Raleway', sans-serif;

        @media only screen and (max-width: 700px){
            width: 80%;
            height: 60%;
        }

        >.login-header{
            font-size: 2rem;
            color: white;
            text-align: center;
        }

        >.login-text{
            font-size: 1rem;
            color: #AFAFAF;
            margin-bottom: 1.5rem;
        }

        >.auth-container{
            width: 40%;
            padding: 0.5rem 0.5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            border: 1px solid #F25D5A;
            border-radius: 1rem;

            // @media only screen and (max-width: 540px){
            //     border: 0px;
            // }

            >.auth-text{
                font-size: 1rem;
                color: #AFAFAF;

                @media only screen and (max-width: 540px){
                    display: none;
                }
            }

            .auth-image{
                // transform: scale(0.25);
                width: 42px;
                // border: 2px solid #F25D5A;
                border-radius: 100%;
                border: 1px solid #AFAFAF;

                @media only screen and (max-width: 540px){
                    border: 0px;
                }
            }

            &:hover{
                @media only screen and (min-width: 541px){
                    cursor: pointer;
                    border: 1px solid #A9EBFA;
    
                    .auth-image{
                        border: 1px solid #A9EBFA;
                    }
    
                    .auth-text{
                        color: #A9EBFA;
                    }
                }
            }
        }
    }
}
