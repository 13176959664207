.featured-main-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.featured-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    >.featured-arrow{
        &:hover{
            cursor: pointer;
        }
    }

    >.featured-box{
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.5rem;
        // padding: 1rem 3rem;
        width: 70%;
        height: 100%;
        background: rgb(48, 47, 47);
        border-radius: 1.5rem;
        // gap: 4rem;
        gap: 15%;
        // align-items: flex-start;

        @media only screen and (max-width: 450px){
            width: 75%;
            gap: 2rem;
        }

        >.featured-column{
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 50%;
            gap: 1rem;

            >.featured-title{
                color: white;
                font-size: 2rem;
                font-family: 'Raleway', sans-serif;

                @media only screen and (max-width: 450px){
                    font-size: 1.2rem;
                }
            }
            
            >.info-row{
                display: flex;
                flex-direction: row;
                // gap: 3rem;
                width: 100%;
                // max-width: 90%;
                // align-items: center;
                // align-items: space-between;
                justify-content: space-between;
                transform: translateY(-0.5rem);
                flex-wrap: wrap;

                >.featured-artist{
                    color: #AFAFAF;
                    font-size: 1rem;
                    font-family: 'Raleway', sans-serif;
                    margin-bottom: 1rem;
                    max-width: 200px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    @media only screen and (max-width: 450px){
                        font-size: 0.75rem;
                    }
                }

                >.info-container{
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    margin-bottom: 1rem;
                    justify-content: center;
                    align-items: center;

                    >.type{
                        font-size: 0.75rem;
                        color: white;
                        border-radius: 1rem;
                        padding: 0.25rem 0.5rem;
                        height: 15px;

                        @media only screen and (max-width: 450px){
                            font-size: 0.5rem;
                            height: 10px;
                        }
                    }
    
                    >.genre-container{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.25rem;
                        font-family: 'Raleway', sans-serif;
                        color: #AFAFAF;

                        @media only screen and (max-width: 450px){
                            font-size: 0.75rem;
                        }
                    }
    
                    >.Album{
                        background: #CEA452;
                    }
    
                    >.Track{
                        background: #F25D5A;
                    }
                }
            }

            >.album-cover{
                width: 350px;
                height: auto;
                max-width: 100%;

                @media only screen and (max-width: 500px){
                    width: 150px;
                }
            }
        }

        >.right-column{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: space-between;
            // max-width: 50%;
            max-width: 350px;
            height: 100%;
            gap: 1rem;

            @media only screen and (max-width: 500px){
                max-width: 150px;
            }

            >.right-image-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 50%;
                padding: 2rem;
                gap: 0.5rem;
                width: 100%;
                border: 1px solid #AFAFAF;
                box-sizing: border-box;
                border-radius: 0.75rem;

                &:hover{
                    cursor: pointer;
                    border: 1px solid #A9EBFA;
                }

                >.right-image-label{
                    color: white;
                    font-size: 1rem;
                    font-family: 'Raleway', sans-serif;
                    text-align: center;
                }

                >.spotify-image{
                    width: auto;
                    min-width: 70px;
                    max-width: 100%;
    
                    &:hover{
                        cursor: pointer;
                    }
                }
    
                >.soundoff-image{
                    width: auto;
                    max-width: 100%;
                    max-height: 150px;
    
                    &:hover{
                        cursor: pointer;
                    }
                }
            }

        }
    }
}

.dots-container {
    display: flex;
    justify-content: center; /* Center the dots horizontally */
    margin-top: 2rem; /* Add some spacing above the dots */

    >.dot {
        &:hover{
            cursor: pointer;
        }
    }   
}