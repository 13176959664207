@import url('https://fonts.googleapis.com/css2?family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.search-result{
    // width: 100%;
    width: 80%;
    background: rgb(48, 47, 47);
    height: 7.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.75rem;
    max-width: 100%;
    box-sizing: border-box;

    &:hover{
        cursor: pointer;
    }

    >.album-cover{
        max-height: 100%;
        width: auto;
    }

    >.album-info{
        display: flex;
        flex-direction: column;
        font-family: 'Raleway', sans-serif;
        gap: 0.5rem;

        >.album-name{
            color: white;
            font-size: 1.5rem;
        }

        >.info-row{
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;

            >.type-label{
                font-size: 0.75rem;
                color: white;
                border-radius: 1rem;
                padding: 0.25rem 0.5rem;
            }

            >.album{
                background: #CEA452;
            }

            >.artists{
                color: #AFAFAF;
            }
        }
    }
}