@import url('https://fonts.googleapis.com/css2?family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.search-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.results{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
}

.search-form{
    width: 77.5%;
    margin-top: 2rem;
    // box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 1rem;

    .search-input{
        width: 100%;
        height: 3rem;
        border-radius: 1rem;
        background: #232323;
        padding: 0.5rem;
        border: 2px solid #AFAFAF;
        color: #AFAFAF;
        font-family: 'Raleway', sans-serif;
    
        &:focus{
          color: white;
          outline: none;
          border-color: #A9EBFA;
        }
    }
}
