.username-overlay-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: absolute;

    >.username-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 75%;
        background-color: #232323;
        border-radius: 1rem;
        padding: 1rem;
        gap: 1.5rem;
        font-family: 'Raleway', sans-serif;

        >.username-box-header{
            color: white;
            font-size: 3rem;
            // margin-bottom: 1rem;
            text-align: center;
        }

        >.username-box-text{
            color: #AFAFAF;
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        >#username-form{
            width: 75%;
            max-width: 500px;

            > .username-input{
                width: 100%;
                height: 2rem;
                border-radius: 1rem;
                background: #232323;
                padding: 0.5rem;
                border: 2px solid #AFAFAF;
                color: #AFAFAF;
                font-family: 'Raleway', sans-serif;
            
                &:focus{
                  color: white;
                  outline: none;
                  border-color: #A9EBFA;
                }
            }
        }
    }
}

