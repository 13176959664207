.dashboard-content{
    width: 100%;
    height: 90.5vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 7rem;

    .section-wrapper{
        display: flex;
        flex-direction: column;
        width: 95%;
        justify-content: center;
        align-items: center;

        >.section-label{
            width: 100%;
            color: white;
            font-size: 2rem;
            font-family: 'Raleway', sans-serif;
            margin-bottom: 3rem;
            padding-left: 2rem;
        }
    }
}