.room-view-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3rem;
    justify-content: flex-start;
    padding-left: 2rem;
    overflow-x: auto;
    max-width: 100%;


}

.room-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    // max-width: 250px;
    background: rgb(48, 47, 47);
    border-radius: 1.5rem;
    padding: 1.5rem 1.5rem;
    gap: 1rem;

    &:hover{
        cursor: pointer;
    }

    >.room-name{
        color: white;
        font-size: 1.5rem;
        font-family: 'Raleway', sans-serif;
    }

    >.room-info-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;

        >.room-type{
            color: white;
            font-size: 1rem;
            font-family: 'Raleway', sans-serif;
            padding: 0.25rem 0.5rem;
            border-radius: 0.5rem;
            background: rgb(64, 64, 64);
        }

        >.Tracks{
            background: #F25D5A;
        }

        >.Artists{
            background: #5ba7b9;
        }

        >.Album{
            background: #CEA452;
        }

        >.room-size{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.25rem;
            color: white;
            font-size: 1rem;
            font-family: 'Raleway', sans-serif;
        }

        >.room-duration{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.25rem;
            color: white;
            font-size: 1rem;
            font-family: 'Raleway', sans-serif;
        }
    }
}