@import url('https://fonts.googleapis.com/css2?family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.navBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 7.5vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    >.logo{
        max-height: 7.5vh;
    }
}

.auth-button {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    padding: 0.5rem;
    border: #afafaf 2px solid;
    border-radius: 0.5rem;
    background: #232323;
    color: #AFAFAF;

}

.sign-out{
    &:hover{
        color: #F25D5A;
        border: #F25D5A 2px solid;
        cursor: pointer;
    }
}

.sign-in{
    &:hover{
        color: #A9EBFA;
        border: #A9EBFA 2px solid;
        cursor: pointer;
    }
}

.logo{
    &:hover{
        cursor: pointer;
    }
}