@keyframes artist-bob{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(0.5rem);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes mic-shudder{
    0% {
        transform: translate(0, 0);
      }
    25% {
    transform: translate(-2.5px, -2.5px);
    }
    75% {
    transform: translate(2.5px, 2.5px);
    }
    100% {
    transform: translate(0, 0);
    }
}

@keyframes album-spin{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}

.create-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: 'Raleway', sans-serif;

    >.create-label{
        font-size: 2rem;
        color: white;
        margin-top: 2rem;
        width: 100%;
        max-width: 100vw;
        padding-left: 2rem;
        box-sizing: border-box;
        margin-bottom: 5rem;
    }

    >.create-cards-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex-wrap: wrap;
        gap: 3rem;

    }
}

.create-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 90%;
    // height: 5rem;
    padding: 3rem;
    background: rgb(48, 47, 47);
    border-radius: 0.75rem;
    margin: 1rem;
    font-family: 'Raleway', sans-serif;
    border: 1px solid #AFAFAF;
    gap: 2rem;

    >.create-card-icon{
        color: white;
        font-size: 12rem;
    }

    &:hover{
        cursor: pointer;
        border: 1px solid #A9EBFA;

        >.create-card-label{
            color: #A9EBFA;
        }

        >.create-card-icon{
            color: #A9EBFA;
        }

        >.Artists{
            animation: artist-bob 0.5s;
        }

        >.Tracks{
            animation: mic-shudder 0.3s;
        }

        >.Album{
            animation: album-spin 0.5s;
        }
    }

    >.create-card-label{
        color: white;
        font-size: 2rem;
    }
}